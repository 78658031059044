<template>
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :tabItems="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <components
          :key="tab.key"
          :is="tab.component"
          :popupParam="popupParam"
          :equip.sync="equip"
          :attachInfo="attachInfo"
          :isOld="isOld"
          @getDetail="getDetail"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equipment-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        equipmentCd: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'equipmentInfo',
      equip: {
        plantCd: null,  // 사업장코드
        equipmentTypeCd: null,  // 설비유형 코드
        equipmentCd: '',  // 설비코드
        equipmentNo: '',  // 설비코드
        equipmentName: '',  // 설비명
        subProcessCd: '',  // 부공정
        equipmentLocation: '',  // 설비위치
        installDate: '',  // 설치일자
        discardFlag: 'N',
        discardDate: '',  // 폐기일자
        amount: '',  // 수량
        specs: [],
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'EQUIPMENT_PID',
        taskKey: '',
      },
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
    isOld() {
      return Boolean(this.popupParam.equipmentCd)
    },
    tabItems() {
      let items = [
        // 기본정보
        { name: 'equipmentInfo', icon: 'info', label: '기본정보', component: () => import(`${'./equipmentInfo.vue'}`), key: uid() }
      ];
      if (this.isOld) {
        items = this.$_.concat(items, [
          // 설비 사양
          { name: 'equipmentSpec', icon: 'account_tree', label: 'LBL0003288', component: () => import(`${'./equipmentSpec.vue'}`), key: uid() },
        ])
      }
      return items;
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.equipment.get.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.equipmentCd);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.equip, _result.data)
          this.$set(this.attachInfo, 'taskKey', this.popupParam.equipmentCd)
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      }
    },
  }
};
</script>